body {
  margin: 0px;
  padding: 0px;
}

.top-nav-buttons .ant-btn{
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-rating .ant-progress-text {
  color: white !important;
  padding: 5px !important;
  font-size: 12px !important;
  display: inline-block;
}

.ant-picker-ranges {
  gap: 10px;
}

.fade-enter {
  opacity: 0; 
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-out; /* Adjust timing and easing as needed */
}

.fade-exit {
  opacity: 1; 
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;  /* Adjust timing and easing as needed */
}
/*  */
.row {
  display: flex;
}

.seat {
  width: 30px;
  height: 30px;
  border: 1px solid black;
  margin: 2px;
}

.seat.selected {
  background-color: lightblue;
}

.no-text-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.custom-spinner {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.custom-slide-down {
  animation-name: slidedown;
  animation-duration: 1000ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.custom-slide-up {
  animation-name: slideup;
  animation-duration: 1000ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.custom-fade-in {
  animation-name: fadein;
  animation-duration: 500ms;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes spin {
  from {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  to {
    transform: rotate3d(0, 0, 1, 360deg);
  }
}

@keyframes slidedown {
  0% {
    transform: translate3d(0px, -100%, 0px);
  }
  50% {
    transform: translate3d(0px, 0%, 0px);
  }
  100% {
    transform: translate3d(0px, 100%, 0px);
  }
}

@keyframes slideup {
  0% {
    transform: translate3d(0px, 100%, 0px);
  }
  50% {
    transform: translate3d(0px, 0%, 0px);
  }
  100% {
    transform: translate3d(0px, -100%, 0px);
  }
}